<script setup lang="ts">
import { useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type ButtonAs = "button" | "div" | "label";
export type ButtonType = "button" | "submit";

import {
    ButtonAlign,
    buttonClasses,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
} from "./index";
import AppIcon, {
    IconColor,
    IconName,
    IconSize,
} from "@/Components/Shared/icon/AppIcon.vue";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";

type Props = {
    color?: ButtonColor;
    disabled?: boolean;
    as?: ButtonAs;
    size?: ButtonSize | ButtonSize[];
    variant?: ButtonVariant | ButtonVariant[];
    align?: ButtonAlign;
    loading?: boolean;
    icon?: IconName;
    iconAfter?: IconName;
    iconSize?: IconSize;
    iconColor?: IconColor;
    title?: string;
    label?: string | number;
    type?: ButtonType;
};
const {
    as = "button",
    color = "default",
    size = "regular",
    align = "center",
    variant = undefined,
    icon = undefined,
    iconAfter = undefined,
    iconColor = undefined,
    iconSize = "small",
    title = undefined,
    type = "button",
    label = undefined,
} = defineProps<Props>();

const classes: AntlerClasses<Props> = buttonClasses;
const attrs = useAttrs();
const { aClass } = installAntlerComponent(
    "button",
    { color, size, variant, align },
    classes,
    attrs,
);
</script>
<template>
    <component
        :is="as"
        :class="
            aClass({
                'pl-3':
                    icon && (!variant || !variant.includes('round')) && $slots.default,
                'pr-3': iconAfter && (!variant || !variant.includes('round')),
            })
        "
        :disabled="disabled || loading"
        :title="title"
        :role="as !== 'button' ? 'button' : undefined"
        :tabindex="as !== 'button' ? 0 : undefined"
        :type="as === 'button' ? type : undefined"
    >
        <template v-if="!iconAfter">
            <AppLoader v-if="loading" :size="iconSize" />
            <AppIcon
                v-else-if="icon"
                :name="icon"
                :size="iconSize"
                :color="iconColor"
            />
        </template>

        <template v-if="label || $slots.default">
            <slot />

            {{ label }}
        </template>

        <template v-if="iconAfter">
            <AppLoader v-if="loading" />
            <AppIcon
                v-else-if="iconAfter"
                :name="iconAfter"
                :size="iconSize"
                :color="iconColor"
            />
        </template>
    </component>
</template>
